.marquee {
    white-space: nowrap;
    overflow: hidden;
    box-sizing: border-box;
  }
  
  .marquee.scrolling {
    animation: marquee 5s linear infinite;
  }
  
  @keyframes marquee {
    0% { transform: translateX(100%); }
    100% { transform: translateX(-100%); }
  }