/* backrground image that repeats */
.top-splatter {
  background-image: url("./assets/top-splatter-1.png");
  background-repeat: no-repeat;
  background-size: 100% auto;
  /* background-position: center; */
}

.bottom-splatter {
  background-image: url("./assets/bottom-splatter.png");
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
}
